import { acceptHMRUpdate, defineStore } from "pinia";
import { checkIfRolesHasSpecificRole } from "~/shared/helpers/roles.helper";
import type { User } from "~/types/ISignInResponse";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: {
      name: "",
      user_config: {}
    } as Partial<User>,
  }),

  getters: {
    isRolesEmpty(): boolean {
      if (!this.user) return true;
      if (!this.user?.roles) return true;

      return this.user?.roles?.length === 0;
    },
    getUsername(): string {
      if (!this.user) {
        throw new Error("PINIA ERROR: user is undefined");
      }
      if (!this.user?.name) {
        throw new Error("PINIA ERROR: user.name is undefined");
      }

      return this.user.name;
    },
    sellerCpf(): string {
      if (!this.user?.cpf) {
        throw new Error("PINIA ERROR: user.cpf is undefined");
      }
      return this.user?.cpf;
    },
    isMaster(): boolean {
      if (this.isRolesEmpty) return false;
      return checkIfRolesHasSpecificRole("MASTER", this.user?.roles);
    },

    isManager(): boolean {
      if (this.isRolesEmpty) return false;
      return checkIfRolesHasSpecificRole("GERENTE", this.user?.roles);
    },

    isCoordinator(): boolean {
      if (this.isRolesEmpty) return false;
      return checkIfRolesHasSpecificRole("COORDENADOR", this.user?.roles);
    },

    isSupervisor(): boolean {
      if (this.isRolesEmpty) return false;
      return checkIfRolesHasSpecificRole("SUPERVISOR", this.user?.roles);
    },

    isPromoter(): boolean {
      if (this.isRolesEmpty) return false;
      return checkIfRolesHasSpecificRole("PROMOTOR", this.user?.roles);
    },

    isSalesman(): boolean {
      return checkIfRolesHasSpecificRole("VENDEDOR", this.user?.roles);
    },

    isBkoManager(): boolean {
      return checkIfRolesHasSpecificRole("BKO_GERENTE", this.user?.roles);
    },

    isBko(): boolean {
      return checkIfRolesHasSpecificRole("BKO", this.user?.roles);
    },

    isCommAdmVendas(): boolean {
      return checkIfRolesHasSpecificRole("COMM_ADM_VENDAS", this.user?.roles);
    },

    canRenderBkoSidebarItem(): boolean {
      return this.isMaster || this.isBkoManager || this.isBko;
    },

    canRenderSaleSidebarItem(): boolean {
      if (this.isBkoManager || this.isBko) return false;
      if (
        this.isSalesman ||
        this.isMaster ||
        this.isPromoter ||
        this.isCoordinator ||
        this.isSupervisor
      )
        return true;

      return false;
    },

    canRenderLeadSidebarItem(): boolean {
      if (
        this.isManager ||
        this.isMaster ||
        this.isSalesman ||
        this.isSupervisor ||
        this.isCoordinator
      )
        return true;

      return false;
    },

    canRenderRegistrations(): boolean {
      return this.isMaster || this.isManager || this.isCoordinator;
    },

    canRenderReports(): boolean {
      return this.isMaster || this.isManager || this.isCoordinator;
    },

    canRenderMyExtract(): boolean {
      return (
        this.isMaster ||
        this.isManager ||
        this.isCoordinator ||
        this.isSalesman ||
        this.isPromoter ||
        this.isSupervisor
      );
    },

    canRenderOfferShares(): boolean {
      return (
        this.isMaster ||
        this.isManager ||
        this.isCoordinator ||
        this.isSalesman ||
        this.isSupervisor
      );
    },

    canRenderSaleHistory(): boolean {
      return (
        this.isMaster ||
        this.isManager ||
        this.isCoordinator ||
        this.isSalesman ||
        this.isSupervisor ||
        this.isBko ||
        this.isBkoManager ||
        this.isPromoter ||
        this.isCommAdmVendas
      );
    },

    canRenderPlanPerCategory(): boolean {
      return (
        this.isMaster || this.isManager || this.isCoordinator || this.isSalesman
      );
    },

    shouldRenderSaleComments(): boolean {
      const emailAuthCommentSale = [
        "yuri@twocommerce.com.br",
        "prodrigues@commcenter.com.br",
        "r.filho@vertex.vertexdigital.co",
      ];
      const userEmail = this.user?.email ?? "";
      return emailAuthCommentSale.includes(userEmail);
    },

    userThemes(): Array<string> {
      return this.user.user_config?.themes || []
    }
  },

  actions: {
    setUser(user: User) {
      this.user = {
        ...user
      };
      
      this.setUserConfig(user)
    },

    setUserConfig(user) {
      if (!user.user_config) {
        this.user.user_config = {
          themes: ['vendapp']
        }
      }
    },




    logout() {
      this.user = {
        name: "",
      };
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
